import React from 'react'
import { Link } from 'react-router-dom';
function Home() {
  return (
  <>
 <div>
  <section>
    <div className="contact-wrapper">
      <div className="content-wrapper text-center">
        <p className="title-text mb-0">
          Payment switch
        </p>
        <p className="contact-heading">
          <span className="game-text">
            Payment switch
          </span> <span className="industry-text">
            Industry
          </span>
        </p>
        <Link to="/contact">
              <button className="contact-btn">Contact us</button>
            </Link>
      </div>
    </div>
  </section>
  <section className="key-section">
    <div className="container"><div className="logoimg" style={{textAlign: 'center'}}><img src="img/logo.png" style={{marginBottom: 10}} alt /></div>
      <div className="mx-auto text-center">
        <div className="heading-wrapper mx-auto">
          <p className="key-heading">
            Authorization core module
          </p>
          <p className="key-para">
            A central component for the collection of all payment transactions. Sends payments to different processing centers based on 
            configured business rules. Receives and concentrates all transactions from the terminal network. Acts as an authorization 
            switch for routing transactions.
          </p>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
          <div className="d-flex gap-4 mb-3">
            <div className="pt-1">
              <img src="./img/Vector (3).png" />
            </div>
            <div className>
              <p className="features-heading mb-0">
                Transaction authorization
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 mb-3">
            <div className="pt-1">
              <img src="./img/Vector (3).png" />
            </div>
            <div className>
              <p className="features-heading mb-0">
                Transaction routing via an H2H connection to the selected processing center
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 mb-3">
            <div className="pt-1">
              <img src="./img/Vector (3).png" />
            </div>
            <div className>
              <p className="features-heading mb-0">
                Ability to connect terminal networks of various partners
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
          <div className="d-flex gap-4 mb-3">
            <div className="pt-1">
              <img src="./img/Vector (3).png" />
            </div>
            <div className>
              <p className="features-heading mb-0">
                Terminal network key management (shielded from authorization center keys)
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 mb-3">
            <div className="pt-1">
              <img src="./img/Vector (3).png" />
            </div>
            <div className>
              <p className="features-heading mb-0">
                Transaction verification and risk management modules that excelled in high-risk environments
              </p>
            </div>
          </div>
          <div className="d-flex gap-4 mb-3">
            <div className="pt-1">
              <img src="./img/Vector (3).png" />
            </div>
            <div className>
              <p className="features-heading mb-0">
                Resilient, scalable performance (millions of transactions per day)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  </>
  )
}

export default Home